import React from "react";
import { Col, Row, Image } from "react-bootstrap";
import indent from "../helpers/indent";

const FormatContent = ({ content, imageMap }) => (
  content.map((obj, i) => (
    <Row key={i}>
      <Col>
        <>
          {obj.p &&
            <>
              <p>{indent(obj.p)}</p>
              <p />
            </>}
          {obj.div &&
            <>
              <div>{obj.div}</div>
              <p />
            </>}
          {obj.bold &&
            <>
              <p><b>{obj.bold}</b></p>
            </>}
          {obj.rightAlign &&
            <>
              <p style={{ textAlign: "right" }}>{obj.rightAlign}</p>
            </>}
          {obj.list &&
            <ul>
              {obj.list.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          }
          {obj.letterHead &&
            <p>
              <Image src={imageMap[obj.letterHead]} style={{ width: "35rem", height: "5rem" }} />
            </p>
          }
          {obj.signatureImage &&
            <p style={{ textAlign: "right" }}>
              <Image src={imageMap[obj.signatureImage]} style={{ width: "12rem", height: "5rem" }} />
            </p>
          }
        </>
      </Col>
    </Row>

  ))
);

export default FormatContent;