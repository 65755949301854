import { GlobalNav, About, Services, Testimonials, Photos, Contact, Header, Footer } from "./Components/index.js";
import { HashRouter, Switch, Route } from "react-router-dom";
import { Jumbotron, Container } from "react-bootstrap";

const ROUTES = [
  {
    path: "/about",
    component: About,
    title: "About Us",
  },
  {
    path: "/services",
    component: Services,
    title: "Services",
  },
  {
    path: "/testimonials",
    component: Testimonials,
    title: "Testimonials",
  },
  {
    path: "/photos",
    component: Photos,
    title: "Photos",
  },
  {
    path: "/contact",
    component: Contact,
    title: "Contact",
  },
]

const App = () => {

  return (
    <Container>
      <GlobalNav />
      <Jumbotron>
        <HashRouter>
          <Switch>
            {
              ROUTES.map(({ path, component: Component, title }, key) => (
                <Route
                  exact
                  path={path || null}
                  render={() => (
                    <>
                      <Header title={title} />
                      <p />
                      <Container style={{ margin: "1rem" }}>
                        <Component />
                      </Container>
                    </>
                  )}
                  key={key} />
              ))
            }
            <Route render={() => (
              <>
                <Header title={"About Us"} />
                <p />
                <Container>
                  <About />
                </Container>
              </>
            )} />
          </Switch>
        </HashRouter>
      </Jumbotron>
      <Footer children={<a href="http://charlesneblett.com" target="_blank">Designed by Charles Neblett</a>} />
    </Container>
  );
}

export default App;
