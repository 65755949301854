import logo from '../assets/logo.jpg';
import React from "react";
import { Navbar, Nav } from "react-bootstrap";

const GlobalNav = () => {

  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand href=""><img src={logo} className="App-logo" alt="South Central Water Company" width="300" height="90" /></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="#about">About</Nav.Link>
          <Nav.Link href="#services">Services</Nav.Link>
          <Nav.Link href="#testimonials">Testimonials</Nav.Link>
          <Nav.Link href="#photos">Photos</Nav.Link>
          <Nav.Link href="#contact">Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )

}

export default GlobalNav;