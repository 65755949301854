import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import CopperCanyon from "../assets/CopperCanyon.jpg"
import CopperCanyonEntrance1 from "../assets/CopperCanyonEntrance1.jpg"
import CopperCanyonEntrance2 from "../assets/CopperCanyonEntrance2.jpg"
import Edgebrook from "../assets/Edgebrook.jpg"
import PlantOverhead from "../assets/PlantOverhead.jpg"

const Photos = () => (
  <Container>
    <Row>
      <Col>
        <Image src={CopperCanyon} thumbnail />
      </Col>
      <Col>
        <Image src={CopperCanyonEntrance1} thumbnail />
      </Col>
      <Col>
        <Image src={CopperCanyonEntrance2} thumbnail />
      </Col>
    </Row>
    <p />
    <Row>
      <Col>
        <Image src={Edgebrook} thumbnail />
      </Col>
      <Col>
        <Image src={PlantOverhead} thumbnail />
      </Col>
    </Row>
  </Container>
)

export default Photos;