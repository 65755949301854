import React from "react";
import { TESTIMONIALS } from "../assets/content.json";
import FormatContent from "./FormatContent";
import Milam from "../assets/letterheads/MilamRealEstateCapital.jpg";
import wplackcarr from "../assets/signatures/wplackcarr.jpg"
import { Card, Container, Jumbotron } from "react-bootstrap";

const imageMap = {
  "Milam": Milam,
  "wplackcarr": wplackcarr
}

const Testimonials = () => (
  <Container>
    <FormatContent content={TESTIMONIALS} imageMap={imageMap} />
  </Container>
)

export default Testimonials;